@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

html {
  font-size: 100%;
  box-sizing: border-box;
  font-family: "lato", Arial, Helvetica, sans-serif;
}

body {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  background-image: linear-gradient(0deg, gray, #000000);
  text-align: center;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

div.content {
  animation: slideIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate3d(-20rem, 0, 500rem);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

h1 {
  color: #8a8a8a;
}

ul.navBorder {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  border: 3px solid #8a8a8a;
  display: inline-block;
  width: 100%;
}

ul.light-theme {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  border: 3px solid #000000;
  display: inline-block;
  width: 100%;
}

li {
  color: #ffffff;
}

body.light-theme li {
  color: #000000;
}

ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

a {
  color: #8a8a8a;
  border-radius: 10%;
}

a.navitem {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  padding: 0.5% 0.5%;
  position: relative;
}

a.navitem:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 20%;
  position: relative;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

a.navitem:hover:after {
  width: 100%;
  left: 0;
}

i {
  color: #ffffff;
}

p {
  color: #ffffff;
  text-align: center;
}

div.light-theme {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  background-image: linear-gradient(0deg, gray, #ffffff);
  text-align: center;
  color: #000000;
}

body.light-theme h1 {
  color: #363636;
}

body.light-theme ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

body.light-theme a {
  color: #3b3b3b;
}

div a.light-theme {
  color: #8a8a8a;
}

div a.light-theme:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: relative;
  background: rgb(0, 0, 0);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

div a.light-theme:hover {
  left: 0;
}

body.light-theme i {
  color: #000000;
}

body.light-theme p {
  color: #000000;
  text-align: center;
}

body.light-theme .navborder {
  border: 3px solid #242424;
}

nav {
  padding: 1%;
  padding-right: 2%;
}

.circle {
  background-color: #3d3d3d;
  border-radius: 20%;
}

body.light-theme .circle {
  background-color: #9e9e9e;
}

.circle:hover {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  100% {
    transform: scale(1);
  }
}

.arrow {
  border-radius: 20%;
}

.arrow:hover {
  animation: pulsing2 1s infinite;
}

@keyframes pulsing2 {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  100% {
    transform: scale(1);
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
